var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{staticClass:"ma-2 flex-column"},[_c('div',{staticClass:"d-flex align-center justify-space-between ma-4 flex-wrap",staticStyle:{"gap":"10px"}},[_c('h1',{staticClass:"w-max fz-20 font-weight-medium"},[_vm._v("Contacts ("+_vm._s(_vm.contacts.length)+")")]),_c('v-btn',{staticClass:"font-weight-medium w-max text-none ml-auto",attrs:{"color":"primary","elevation":"0"},on:{"click":function($event){return _vm.$router.push({ name: 'NewContact' })}}},[_c('v-icon',{attrs:{"left":""}},[_vm._v("mdi-plus")]),_vm._v(" Create Contact ")],1)],1),_c('v-data-table',{staticClass:"w-100",class:{ 'hide-page-arrows': _vm.itemsPerPage >= _vm.total },attrs:{"headers":_vm.headers,"items":_vm.contacts,"loading":_vm.loading,"height":"calc(100vh - 215px)","item-key":"uuid","page":_vm.page,"items-per-page":_vm.itemsPerPage,"footer-props":{
      'items-per-page-options': [10, 20, 50, 100]
    }},on:{"update:page":function($event){_vm.page=$event},"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event},"update:options":function($event){return _vm.loadContactList()}},scopedSlots:_vm._u([{key:"header.actions",fn:function(){return [_c('span',{staticClass:"mr-4"},[_vm._v("Actions")])]},proxy:true},{key:"footer.page-text",fn:function(){return [_c('span',{staticClass:"mr-4"},[_vm._v("Total: "+_vm._s(_vm.total))]),(_vm.itemsPerPage < _vm.total)?_c('span',[_vm._v("Page: "+_vm._s(_vm.page))]):_vm._e()]},proxy:true},{key:"item.name",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(item.first_name)+" "+_vm._s(item.last_name)+" ")]}},{key:"item.phone_number",fn:function(ref){
    var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.formatPhone(item.phone_number))+" ")]}},{key:"item.labels",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"label-wrapper"},_vm._l((item.userLabel),function(label,index){return _c('v-chip',{key:index + 'label',staticClass:"fz-12 pa-2"},[_vm._v(" "+_vm._s(label.label)+" ")])}),1)]}},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_c('div',{staticClass:"d-flex align-center justify-end"},[_c('v-tooltip',{attrs:{"top":"","content-class":"v-tooltip--top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none d-flex align-center px-0",attrs:{"to":{ name: 'EditContact', params: { uuid: item.uuid } },"icon":""}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"#444746"}},[_vm._v("mdi-pencil-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Edit")])]),_c('v-tooltip',{attrs:{"top":"","content-class":"v-tooltip--top"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
    var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({staticClass:"text-none d-flex align-center px-0",attrs:{"icon":""},on:{"click":function($event){return _vm.openDeleteModal(item)}}},'v-btn',attrs,false),on),[_c('v-icon',{attrs:{"size":"20","color":"#444746"}},[_vm._v("mdi-trash-can-outline")])],1)]}}],null,true)},[_c('span',[_vm._v("Delete")])])],1)]}}],null,true)}),_c('v-dialog',{attrs:{"width":"500"},model:{value:(_vm.deleteDialog),callback:function ($$v) {_vm.deleteDialog=$$v},expression:"deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"body-1"},[_vm._v(" Delete "+_vm._s(_vm.selectedContact.first_name)+" "+_vm._s(_vm.selectedContact.last_name)+" ("+_vm._s(_vm.selectedContact.phone_number)+") "),_c('v-spacer'),_c('v-btn',{attrs:{"small":"","icon":""},on:{"click":_vm.closeDeleteModal}},[_c('v-icon',[_vm._v("mdi-close")])],1)],1),_c('v-card-text',[_vm._v(" Are you sure you want to delete this contact? ")]),_c('v-card-actions',{staticClass:"pb-5"},[_c('v-spacer'),_c('v-btn',{staticClass:"px-5 py-5",attrs:{"small":"","color":"primary","elevation":"0","outlined":""},on:{"click":_vm.closeDeleteModal}},[_vm._v("Cancel ")]),_c('v-btn',{staticClass:"px-5 py-5",attrs:{"loading":_vm.loadingDelete,"small":"","color":"primary","elevation":"0"},on:{"click":_vm.deleteSelectedContact}},[_vm._v("Delete ")])],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }